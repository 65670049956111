import './bootstrap';
import { Toast } from 'tw-elements';
import * as Sentry from '@sentry/browser';

Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
    environment: import.meta.env.MODE,
    integrations: [
        Sentry.replayIntegration({
            maskAllText: false,
        }),
    ],
    // Session Replay
    replaysSessionSampleRate: import.meta.env.MODE == 'production' ? 0.1 : 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    beforeSend: function (event) {
        return event;
    },
    ignoreErrors: ['AbortError: The play() request was interrupted by a new load request'],
});

function formatMessage(message) {
    if (typeof message === 'string') {
        return message;
    }

    if (typeof message === 'object') {
        return Object.values(message)
            .map((m) => (Array.isArray(m) ? m[0] : m))
            .join('<br>');
    }

    return '';
}

function scrollToError(message) {
    if (typeof message === 'object') {
        const firstError = Object.keys(message)[0];
        const element = document.querySelector(`[data-name="${firstError}"]`);
        if (element) {
            element.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'nearest',
            });
        }
    }
}
